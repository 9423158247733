<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        a) A
        <number-value :value="mass1" unit="\text{g}" />
        sample of industrial wastewater was determined to contain
        <number-value :value="mass2" unit="\text{mg}" />
        of mercury. Express the concentration of the wastewater in ppm.
      </p>

      <calculation-input
        v-model="inputs.ppm1"
        class="mb-5"
        prepend-text="$\text{Concentration:}$"
        append-text="$\text{ppm Hg}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        b) What is the ppm of calcium in a
        <number-value :value="mass3" unit="\text{g}" />
        soil sample if it is found that there are
        <number-value :value="mass4" unit="\mu\text{g}" />
        of calcium in the sample?
      </p>

      <calculation-input
        v-model="inputs.ppm2"
        prepend-text="$\text{Concentration:}$"
        append-text="$\text{ppm Ca}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';

export default {
  name: 'Question410',
  components: {
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        ppm1: null,
        ppm2: null,
      },
    };
  },
  computed: {
    mass1() {
      return this.taskState.getValueBySymbol('mass1').content;
    },
    mass2() {
      return this.taskState.getValueBySymbol('mass2').content;
    },
    mass3() {
      return this.taskState.getValueBySymbol('mass3').content;
    },
    mass4() {
      return this.taskState.getValueBySymbol('mass4').content;
    },
  },
};
</script>
